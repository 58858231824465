import { useParams } from '@reach/router';
import { graphql } from 'gatsby';
import React, { FC } from 'react';

import { Card, CardBody, CardOptions, CardOptionsButton } from '@/components';
import { useDataGrid } from '@/components/DataGrid';
import { NextIcon, PreviousIcon, RefreshIcon } from '@/components/icons';
import { useTranslate } from '@/contexts';
import { KycProviderType, PlayerKycHistorySearchOrder } from '@/globalTypes';
import { assert } from '@/utils/error';
import { SanityPlayerKycHistoryBlockFragment } from './__generated__/SanityPlayerKycHistoryBlockFragment';
import usePlayerKycHistory from './usePlayerKycHistory';

export const Fragment = graphql`
  fragment SanityPlayerKycHistoryBlockFragment on SanityPlayerKycHistoryBlock {
    title {
      ...LocaleString
    }
  }
`;

const PlayerKycHistoryBlock: FC<{
  block: SanityPlayerKycHistoryBlockFragment;
}> = ({ block }) => {
  const params = useParams();
  const { t } = useTranslate();

  assert(params.playerId, 'missing playerId');

  const {
    history,
    fetching,
    nextPage,
    previousPage,
    refresh,
    orderBy,
    desc,
    setOrderBy,
    defaultFilter,
    updateFilter,
  } = usePlayerKycHistory(params.playerId);

  const { DataGrid, GlobalCardOptions } = useDataGrid({
    name: 'playerKYCHistory',
    data: history,
    fetching,
    orderBy,
    setOrderBy,
    desc,
    defaultFilter,
    updateFilter,
    schema: (s) => [
      s.dateTimeValue({
        field: 'processedAt',
        title: 'Time',
        fromFilterField: 'from',
        toFilterField: 'to',
        orderBy: PlayerKycHistorySearchOrder.processedAt,
      }),
      s.enumValue({
        field: 'kycProvider',
        title: 'Provider',
        e: KycProviderType,
        filterField: 'kycProvider',
      }),
      s.initiatorValue({
        field: 'initiator',
        title: 'Initiator',
      }),
      s.phoneNumberValue({
        field: 'phoneNumber',
        title: 'Phone Number',
      }),
      s.stringValue({
        field: 'dateOfBirth',
        title: 'Date of Birth',
      }),
      s.stringValue({
        field: 'firstName',
        title: 'First Name',
      }),
      s.stringValue({
        field: 'lastName',
        title: 'Last Name',
      }),
      s.stringValue({
        field: 'street',
        title: 'Street',
      }),
      s.stringValue({
        field: 'city',
        title: 'City',
      }),
      s.stringValue({
        field: 'zipCode',
        title: 'Zip Code',
      }),
      s.stringValue({
        field: 'ssn',
        title: 'SSN',
      }),
      s.stringValue({
        field: 'gender',
        title: 'Gender',
      }),
      s.countryValue({
        field: 'countryCode',
        title: 'Country',
      }),
    ],
  });
  return (
    <Card
      size="lg"
      title={t(block.title)}
      showOptionsAtBottom
      options={
        <CardOptions>
          <CardOptionsButton
            disabled={!previousPage}
            onClick={() => previousPage && previousPage()}
          >
            <PreviousIcon />
          </CardOptionsButton>
          <CardOptionsButton
            disabled={!nextPage}
            onClick={() => nextPage && nextPage()}
          >
            <NextIcon />
          </CardOptionsButton>
          <GlobalCardOptions />
          <CardOptionsButton
            onClick={() => refresh({ requestPolicy: 'network-only' })}
          >
            <RefreshIcon />
          </CardOptionsButton>
        </CardOptions>
      }
    >
      <CardBody>
        <DataGrid />
      </CardBody>
    </Card>
  );
};

export default PlayerKycHistoryBlock;
